import React from "react";
import styled from "@emotion/styled";

import Container from "../container";
import {md} from "../../utils/breakpoints";

const Seperator = styled.div<any>`
    margin: 100px auto;
    width: 140px;
    height: 1px;
    background: ${props => props.theme.borderColor};

    @media (max-width: ${md}px) {
        margin: 50px auto;
    }
`;

const QuoteBox = styled.div<any>`
   
    padding: 40px;
    max-width: 800px;
    margin: 0 auto 100px;
    font-size: 24px;
    line-height: 1.6;
    text-align: center;

    @media (max-width: ${md}px) {
        margin-bottom: 50px;
        font-size: 18px;
    }
`;

const QuoteContent = styled.div<any>`
    margin-bottom: 30px;
`;

const QuoteAuthor = styled.div<any>`
    font-style: italic;
`;

export interface IFootQuoteProps {
    footQuoteContent: string;
    footQuoteAuthor: string;
}

export default function FootQuote(props: IFootQuoteProps): JSX.Element {
    const {footQuoteContent, footQuoteAuthor} = props;

    return (
        <Container>
            <Seperator />
            <QuoteBox>
                <QuoteContent>
                    {footQuoteContent}
                </QuoteContent>
                <QuoteAuthor>
                    {footQuoteAuthor}
                </QuoteAuthor>
            </QuoteBox>
        </Container>
    );
}