import React from "react";
import styled from "@emotion/styled";
import {Link} from "gatsby";

import Underliner from "../underliner";
import Container from "../container";
import {md} from "../../utils/breakpoints";

const TeamContainer = styled.div`
    margin: 140px 0;

    @media (max-width: ${md}px) {
        margin: 80px 0;
    }
`;

const Tagline = styled.h1<any>`
    font-size: 36px;
    font-weight: 400;
    color: ${props => props.theme.primaryColor};
    display: block;
    margin: 0px auto 80px;
    text-align: center;
    line-height: 1.4;

    @media (max-width: ${md}px) {
        font-size: 26px;
    }
`;

const Subhead = styled.h2<any>`
    font-size: 24px;
    font-weight: 400;
    font-family: ${props => props.theme.contentFontFamily};
    color: ${props => props.theme.primaryColor};;
    margin: 0 auto 20px;
    text-align: center;
    display: block;
    width: 100%;
    line-height: 1.4;

    @media (max-width: ${md}px) {
        font-size: 18px;
    }
`;

const TeamContentCardBorder = styled.div<any>`
    padding: 6px;
    border: 1px solid ${props => props.theme.primaryColor};
    transition: border-color .2s ease-in-out;
`;

const TeamContentCard = styled(Link)<any>`
    display: block;
    text-decoration: none;
    max-width: 800px;
    margin: 0px auto;
    font-size: 24px;
    line-height: 1.4;
    background: #fff;
    padding: 10px;

    &:hover {
        ${TeamContentCardBorder} {
            border-color: ${props => props.theme.secondaryColor};
        }
    }
`;

const TeamContent = styled.div<any>`
    padding: 40px;
    color: ${props => props.theme.primaryColor};

    @media (max-width: ${md}px) {
        font-size: 18px;
    }
`;

const LearnMore = styled.div<any>`
    color: ${props => props.theme.secondaryColor};
    text-transform: uppercase;
    text-decoration: underline;
    margin-top: 20px;
`;

export interface ITeamProps {
    teamTagline: string;
    teamTaglineUnderline: string;
    teamSubhead: string;
    teamDescription: string;
}

export default function Team(props: ITeamProps): JSX.Element {
    const {
        teamTagline,
        teamTaglineUnderline,
        teamSubhead,
        teamDescription
    } = props;

    return (
        <TeamContainer>
            <Container>
                <Subhead>
                    {teamSubhead}
                </Subhead>
                <Tagline>
                    <Underliner text={teamTagline} underline={teamTaglineUnderline} />
                </Tagline>
                <TeamContentCard to="/team">
                    <TeamContentCardBorder>
                        <TeamContentCardBorder>
                            <TeamContent>
                                {teamDescription}
                                <LearnMore>
                                    Learn More
                                </LearnMore>
                            </TeamContent>
                        </TeamContentCardBorder>
                    </TeamContentCardBorder>
                </TeamContentCard>
            </Container>
        </TeamContainer>
    );
}