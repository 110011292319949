import React from "react";
import {Link} from "gatsby";
import styled from "@emotion/styled";
import {useTheme} from "emotion-theming";

import {ICaseStudyFeature, ITheme} from "../utils/types";
import BoxLink from "./boxLink";
import {md} from "../utils/breakpoints";

const FeatureContainer = styled.div<any>`
    padding: 6px;
    border: 1px solid ${props => props.withBorder ? props.theme.primaryColor : "transparent"};
    margin: 10px;
    width: 100%;
    min-width: 350px;
    flex: 1 1 calc(50% - 40px);
`;

const FeatureContent = styled.div<any>`
    height: 100%;
    padding: 40px;
    border: 1px solid ${props => props.withBorder ? props.theme.primaryColor : "transparent"};

    @media (max-width: ${md}px) {
        padding: 20px;
    }
`;

const ClientLogo = styled.img<any>`
    height: 40px;

    @media (max-width: ${md}px) {
        height: 30px;
    }
`;

const StudyPreview = styled.div<any>`
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 24px;

    @media (max-width: ${md}px) {
        font-size: 20px;
    }
`;

const LearnLinkContainer = styled.div`
    margin: 30px 0;
`;

const ServicesList = styled.ul<any>`
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    font-size: 16px;

    > li {
        margin :0;
        display: inline-block;
        margin-left: 10px;

        &:first-of-type {
            margin-left: 0;
        }
    }
`;

const ServiceLink = styled(Link)<any>`
    color: ${props => props.theme.primaryColor};
`;

export interface ICaseStudyFeatureProps {
    caseStudyFeature: ICaseStudyFeature;
    withBorder: boolean;
}

export default function CaseStudyFeature(props: ICaseStudyFeatureProps): JSX.Element {
    const {caseStudyFeature, withBorder} = props;

    const theme = useTheme<ITheme>();
    
    return (
        <FeatureContainer withBorder={withBorder}>
            <FeatureContent withBorder={withBorder}>
                <ClientLogo src={caseStudyFeature.client.logoURL} alt={caseStudyFeature.client.name + " Logo"}/>
                <StudyPreview>
                    {caseStudyFeature.previewText}
                </StudyPreview>
                <LearnLinkContainer>
                    <BoxLink
                        borderColor={theme.secondaryColor}
                        textColor={theme.primaryColor}
                        to={`/case-studies/${caseStudyFeature.slug}`}>
                            Learn More
                    </BoxLink>
                </LearnLinkContainer>
                <ServicesList>
                    <li>Services: </li>
                    {caseStudyFeature.services.map(s => (
                        <li key={s.slug}>
                            <ServiceLink to={`/services`}>
                                {s.name}
                            </ServiceLink>
                        </li>
                    ))}
                </ServicesList>
            </FeatureContent>
        </FeatureContainer>
    );
}