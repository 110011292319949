import React from "react";
import styled from "@emotion/styled";
import {Link} from "gatsby";

import {ICaseStudyFeature} from "../../utils/types";
import CaseStudyFeature from "../caseStudyFeature";
import {md} from "../../utils/breakpoints";

const FeaturedRow  = styled.div<any>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    @media(max-width: ${md}px) {
        display: ${props => props.hideMobile ? "none" : "flex"};
    }
`;

const FeaturedContainer = styled.div`
    max-width: 86em;
    margin: 0px auto 100px;
    padding: 0 20px;
`;

const MoreContainer = styled.div`
    text-align: center;
    margin: 40px;
`;

const MoreLink = styled(Link)<any>`
    color: ${props => props.theme.primaryColor};
    font-size: 18px;
`;

export interface IFeaturedCaseStudiesProps {
    caseStudyFeatures: Array<ICaseStudyFeature>
}

export default function FeaturedCaseStudies(props: IFeaturedCaseStudiesProps): JSX.Element {
    const csf = props.caseStudyFeatures;

    return (
        <FeaturedContainer>
            {csf.length >= 2 && (
            <FeaturedRow>
                <CaseStudyFeature caseStudyFeature={csf[0]} withBorder={true}/>
                <CaseStudyFeature caseStudyFeature={csf[1]} withBorder={false}/>
            </FeaturedRow>
            )}
            {csf.length === 4 && (
                <FeaturedRow hideMobile>
                    <CaseStudyFeature caseStudyFeature={csf[2]} withBorder={false}/>
                    <CaseStudyFeature caseStudyFeature={csf[3]} withBorder={true}/>
                </FeaturedRow>
            )}
            <MoreContainer>
                <MoreLink to="/case-studies">
                    More Case Studies
                </MoreLink>
            </MoreContainer>
        </FeaturedContainer>
    );
}