import React from "react"
import {Link, graphql} from "gatsby";
import styled from "@emotion/styled";

import {ICaseStudyFeature, IService} from "../utils/types";
import Layout from "../components/layout/layout";
import Hero from "../components/home/hero";
import FeaturedCaseStudies from "../components/home/featuredCaseStudies";
import Services from "../components/home/services";
import Team from "../components/home/team";
import FootQuote from "../components/home/footQuote";

export default ({ data }) => {
    const {
        tagline,
        taglineUnderline,
        taglineLine2,
        servicesTagline,
        servicesTaglineUnderline,
        servicesSubhead,
        subheadDescription,
        featuredCaseStudies,
        teamTagline,
        teamTaglineUnderline,
        teamSubhead,
        teamDescription,
        featuredServices,
        footQuoteAuthor,
        footQuoteContent
    } = data.contentfulHomePage;

    const services: Array<IService> = featuredServices.map(s => ({
        name: s.serviceName,
        slug: s.serviceSlug,
        definition: s.serviceDefinition,
        shortDescription: s.serviceShortDescription,
        tactics: s.tactics || []
    }));

    const caseStudies: Array<ICaseStudyFeature> = featuredCaseStudies.map(c => ({
        slug: c.caseStudySlug,
        previewText: c.caseStudyPreview.caseStudyPreview,
        client: {
            name: c.client.clientName,
            logoURL: c.client.clientLogo.file.url
        },
        services: services.filter(s => (c.services.some(css => css.serviceSlug === s.slug)))
    }));

    return (
        <Layout title="Home">
            <Hero
                tagline={tagline}
                taglineLine2={taglineLine2}
                taglineUnderline={taglineUnderline}
                subheadDescription={subheadDescription} />
            <FeaturedCaseStudies caseStudyFeatures={caseStudies} />
            <Services
                servicesTagline={servicesTagline}
                servicesTaglineUnderline={servicesTaglineUnderline}
                servicesSubhead={servicesSubhead}
                services={services}/>
            <Team 
                teamDescription={teamDescription.teamDescription}
                teamTagline={teamTagline}
                teamTaglineUnderline={teamTaglineUnderline} 
                teamSubhead={teamSubhead} />
            <FootQuote footQuoteAuthor={footQuoteAuthor} footQuoteContent={footQuoteContent} />
        </Layout>
    );
}

export const query = graphql`
    query {
        contentfulHomePage {
            tagline
            taglineLine2
            taglineUnderline
            subheadDescription
            servicesTagline
            servicesTaglineUnderline
            servicesSubhead
            teamTagline
            teamTaglineUnderline
            teamDescription {
                teamDescription
            }
            teamSubhead
            featuredCaseStudies {
                caseStudyPreview {
                    caseStudyPreview
                }
                caseStudySlug
                client {
                    clientName
                    clientLogo {
                        file {
                            url
                        }
                    }
                }
                services {
                    serviceName
                    serviceSlug
                }
            }
            featuredServices {
                serviceName
                serviceSlug
                serviceDefinition
                serviceShortDescription
                serviceTactics
            }
            footQuoteContent
            footQuoteAuthor
        }
    }
`;